<template>
  <div class="d-flex flex-column dashboard px-4 py-4 w-100">
    <h2 class="mb-4 cursive-font">Preview Accounts</h2>

    <v-alert dismissible>
      From this page, you can view the main HireHeroes website from the POV of
      one of the users.
    </v-alert>

    <table-with-filters
      :isDisabled="state.isUpdating"
      :options="tableOptions"
      :customColumns="customColumns"
    >
      <template v-slot:user-name="{ item }">
        <div>
          <v-avatar size="30px">
            <img :src="resolveProfilePic(item)" alt="" />
          </v-avatar>
          <span class="ml-2">{{ item.username }}</span>
        </div>
      </template>

      <template v-slot:is-disabled="{ item }">
        <v-checkbox
          @change="toggleUserStatus(item)"
          v-model="item.is_disabled"
        ></v-checkbox>
      </template>

      <template v-slot:preview-btn="{ item }">
        <v-btn color="primary" small @click="setPreviewTarget(item)">
          Preview
        </v-btn>
      </template>
    </table-with-filters>
    <view-as v-if="isPreviewing"></view-as>
  </div>
</template>

<script>
import axios from "@axios";
import { resolveProfilePic } from "@/helpers";
import { useViewAs } from "@/composables/accounts/view-as.js";
import { reactive } from "@vue/composition-api";

import ViewAs from "@/components/accounts/ViewAs.vue";
import TableWithFilters from "@/components/common/TableWithFilters.vue";

export default {
  name: "Accounts",
  components: { TableWithFilters, ViewAs },
  setup() {
    const state = reactive({
      isUpdating: false,
    });

    const tableHeaders = [
      { text: "User", value: "userName", sortable: false },
      { text: "Email", value: "email", sortable: false },
      { text: "Type", value: "type", sortable: false },
      { text: "Is Disabled", value: "isDisabled", sortable: false },
      { text: "Preview", value: "previewBtn", sortable: false },
    ];

    const customColumns = [
      { slotName: "user-name", itemValue: "userName" },
      { slotName: "preview-btn", itemValue: "previewBtn" },
      { slotName: "is-disabled", itemValue: "isDisabled" },
    ];

    const filterFields = [
      { label: "Client Name", value: "username", component: "v-text-field" },
      { label: "Client Email", value: "email", component: "v-text-field" },
    ];

    const { setPreviewTarget, isPreviewing } = useViewAs();

    async function toggleUserStatus(user) {
      try {
        state.isUpdating = true;
        await axios.post(`admin/users/${user.id}/status`, {
          isDisabled: user.is_disabled,
        });
      } catch (err) {
        user.is_disabled = !user.is_disabled;
        console.log(err);
      } finally {
        console.log();
        state.isUpdating = false;
      }
    }

    return {
      state,
      tableHeaders,

      tableOptions: {
        endpoint: "/admin/users",
        headers: tableHeaders,
        filtersTitle: "Filter Users",
        filterFields,
      },

      customColumns,

      isPreviewing,
      setPreviewTarget,

      resolveProfilePic,
      toggleUserStatus,
    };
  },
};
</script>
